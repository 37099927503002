/*! ===================================
 *  Author: Roman Nazarkin, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/**
 * Initialize main helper object
 */
var PZTJS = {
    is_safari    : /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    is_firefox   : navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
    is_chrome    : /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
    is_ie10      : navigator.appVersion.indexOf('MSIE 10') !== -1,
    transitionEnd: 'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
    animIteration: 'animationiteration webkitAnimationIteration oAnimationIteration MSAnimationIteration',
    animationEnd : 'animationend webkitAnimationEnd'
};


/**
 * RequestAnimationFrame polyfill
 */
(function() {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
            || window[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function() { callback(currTime + timeToCall); },
                timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
}());


/**
 * Set proper transition & animation event names
 */
(function (m) {
    var transEndEventNames = {
            'WebkitTransition': 'webkitTransitionEnd',
            'MozTransition'   : 'transitionend',
            'OTransition'     : 'oTransitionEnd',
            'msTransition'    : 'MSTransitionEnd',
            'transition'      : 'transitionend'
        },
        animIterationEventNames = {
            'WebkitAnimation': 'webkitAnimationIteration',
            'MozAnimation'   : 'animationiteration',
            'OAnimation'     : 'oAnimationIteration',
            'msAnimation'    : 'MSAnimationIteration',
            'animation'      : 'animationiteration'
        },
        animEndEventNames = {
            'WebkitAnimation' : 'webkitAnimationEnd',
            'MozTAnimation'   : 'animationend',
            'animation'       : 'animationend'
        };

    PZTJS.transitionEnd = transEndEventNames[m.prefixed('transition')];
    PZTJS.animIteration = animIterationEventNames[m.prefixed('animation')];
    PZTJS.animationEnd = animEndEventNames[m.prefixed('animation')];
})(Modernizr);


/**
 * RequestAnimationFrame wrapper
 * @param callback
 * @constructor
 */
PZTJS.RAFit = function (callback) {
    var new_callback = function() {
        callback();
        window.requestAnimationFrame(new_callback);
    };

    new_callback();
};


/**
 * RequestAnimationFrame for scrolling-related callbacks
 */
PZTJS.scrollRAF = function(callback) {
    var lastScrollPos = -1;

    var new_callback = function() {
        var currentScrollPos = window.pageYOffset / document.body.clientHeight;

        if (lastScrollPos !== currentScrollPos) { // avoid calculations if not needed
            lastScrollPos = currentScrollPos;
            callback();
        }
    };

    PZTJS.RAFit(new_callback);
};


/**
 * Returns PHP-defined value
 */
PZTJS.phpData = function (key, default_value) {
    if (typeof default_value === 'undefined') {
        default_value = 'translation not found';
    }

    if (typeof PZT_PHP_DATA !== 'undefined' && PZT_PHP_DATA.hasOwnProperty(key)) {
        return PZT_PHP_DATA[key];
    }

    return default_value;
};


/**
 * Detects whether user is viewing site from a mobile device
 * @param agent
 * @returns {boolean}
 */
PZTJS.isMobile = function (agent) {
    agent = agent || navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent);
};


/**
 * Date.now() polyfill
 */
if (!Date.now) {
    Date.now = function () { return new Date().getTime(); };
}


/**
 * Trigger document ready event on window element (shorthand)
 */
jQuery(document).ready(function () {
    jQuery(window).trigger('docready');
});


/**
 * Shuffle.js proper layout recalculation shorthand
 */
jQuery.fn.pzt_shuffle = function (options) {
    var $ = jQuery,
        $el = $(this);

    $el.each(function () {
        var $current = $(this),
            throttledLayout = $.throttle(300, function () {
                $current.shuffle('update');
            });

        // instantiate the plugin
        $el.shuffle(options);

        // rebuild shuffle layout everytime image gets loaded
        $el.find('img').each(function () {
            if (this.complete && this.naturalWidth !== undefined) {
                return;
            }

            var proxyImage = new Image();
            $(proxyImage).one('load', throttledLayout);
            proxyImage.src = this.src;
        });
    });

    // rebuild all shuffle grids on page load
    $(window).one('load', function () {
        $el.shuffle('update');
    });

    return this;
};


/**
 * Checks if element is visible in current viewport
 * @param el
 * @returns {boolean}
 */
PZTJS.isElementInViewport = function (el) {
    // special bonus for those using jQuery
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        el = el[0];
    }

    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
    );
};